import React, { useState, useEffect } from "react";
import { About } from "./components/about/about";
import { Audit } from "./components/Audit/Audit";
import { Wrapper } from "./components/wrapper/wrapper";
import { WrapperInfo } from "./components/wrapper-info/wrapperInfo";
import { WrapperLogo } from "./components/wrapper-logo/wrapperLogo";
import { HeroContainer } from "./components/hero-container/HeroContainer";
import { BrandsCarousel } from "./components/brands-carousel/BrandsCarousel";
import { Tokenomics } from "./components/tokenomics/Tokenomics";
import { MediaContainer } from "./components/media-container/MediaContainer";
import { RoadMapContainer } from "./components/roadmap-container/RoadMapContainer";
import { Faqs } from "./components/Faqs/Faqs";
import { Footer } from "./components/Footer/footer";
import BuyForm from "./components/BuyForm/BuyForm";

import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div id="container">
      <Wrapper />
      <BuyForm />
      <HeroContainer />
      <BrandsCarousel />
      <About />
      <WrapperInfo />
      <WrapperLogo />
      <Audit />
      <Tokenomics />
      <MediaContainer />
      <RoadMapContainer />
      <Faqs />
      <Footer />
    </div>
  );
};

export default App;
