import React from "react";
import "./about.css";

export const About = () => {
    return (
        <div id="about">
            <div class="vertical-carousel-container">
                <img src="/img/path@v-1.webp" alt="" class="path01" />
                <div class="wrapper">
                    <div class="vertical-carousel">
                        <img src="/img/path@v-2.webp" alt="" class="path02" />
                        <img src="/img/path@v-3.webp" alt="" class="path03" />
                        <div class="image"><img src="/img/furrever-token-about-image.webp" alt="" /></div>
                        <div class="carousel-area">
                            <div class="item">
                                <div class="label-content">Get ready for a paws-itively charming adventure with Furrever Token!</div>
                                <p className="text-content">Our fluffy crypto kitty is here to whisker you away into a world of cuteness and giggles. Dive into the whimsical side of blockchain with our delightful feline friends - it's a meow-nificent blend of crypto fun and adorable antics. Join the Furrever Token family for a purr-fectly joyful ride!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
