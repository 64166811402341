import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './BuyForm.css'
import { Dropdown, DropdownButton } from 'react-bootstrap';
import {
    calculateUSDNeeded, calculateTokenOutput,
    calculateTokensForBNB, calculateBNBNeeded, isValidNumber,
    CONST, useWallet, truncateMiddle
} from '../../services/wallet-service';
import configs from '../../config.main.json';
import Decimal from 'decimal.js';

const BuyForm = () => {
    const currencies = [
        { text: 'ETH', imageSrc: '/img/icon@eth.svg', curr: CONST.ETH, network: CONST.ETH },
        { text: 'USDT ERC20', imageSrc: '/img/icon@erc20.svg', curr: CONST.USDT, network: CONST.ETH },
        { text: 'BNB', imageSrc: '/img/icon@bnb.svg', curr: CONST.BNB, network: CONST.BSC },
        { text: 'USDT BEP20', imageSrc: '/img/icon@bep20.svg', curr: CONST.USDT, network: CONST.BSC },
    ];

    const [selectedItem, setSelectedItem] = useState(currencies[0]);
    const [isOpen, setIsOpen] = useState(false);

    const [network, setNetwork] = useState(CONST.ETH)

    const [networkPrice, setNetworkPrice] = useState(0);


    const [currencyInput, setCurrencyInput] = useState('');
    const [tokenInput, setTokenInput] = useState('');

    const wallet = useWallet(network, configs);

    const [tokenWorth, setTokenWorth] = useState('')
    const [tokenWorthAfterLaunch, setTokenWorthAfterLaunch] = useState('')


    useEffect(() => {
        if (wallet && wallet.boughtTokens) {
            const currentPrice = new Decimal(configs.salePrice);
            const launchPrice = new Decimal('0.0009');
            const amountToken = new Decimal(wallet.boughtTokens);

            const worth = amountToken.times(currentPrice).toFixed(2);
            const launchWorth = amountToken.times(launchPrice).toFixed(2);

            setTokenWorth(formatNumber(worth));
            setTokenWorthAfterLaunch(formatNumber(launchWorth));
        }
    }, [wallet?.boughtTokens, configs.salePrice]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(configs[network]['USDT_Price']); // Assuming 'data.json' is a local file
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                setNetworkPrice(jsonData.price);
            } catch (error) {
                console.error('There was a problem fetching the data:', error);
            }
        };

        fetchData();
    }, [network]);

    function formatNumber(value) {
        // Parse the input value to ensure it's a valid number
        const parsedValue = parseFloat(value);

        // Check if the parsedValue is a valid number
        if (isNaN(parsedValue)) {
            return ""; // Return NaN if the parsedValue is not a number
        }

        // Convert large numbers
        if (parsedValue >= 1000000) {
            return (parsedValue / 1000000).toFixed(2) + 'M'; // Convert to million and round to 2 decimal places
        } else if (parsedValue >= 1000) {
            return (parsedValue / 1000).toFixed(2) + 'K'; // Convert to thousand and round to 2 decimal places
        } else {
            return parsedValue.toString(); // Return the original number if it's smaller than 1000
        }
    }

    useEffect(() => {
        if (!currencyInput) {
            setTokenInput("")
            return
        };
        if (selectedItem.curr === CONST.BNB || selectedItem.curr === CONST.ETH) {
            const tokens = calculateTokensForBNB(currencyInput, networkPrice, configs['salePrice'])
            setTokenInput(tokens)
        }
        else {
            const tokens = calculateTokenOutput(currencyInput, configs['salePrice'])
            setTokenInput(tokens)
        }
    }, [currencyInput])

    const handleCurrencyInputChange = (event) => {

        const { value } = event.target;
        if (value === "") {
            setCurrencyInput(value);
            setTokenInput("")
            return
        }
        // Regular expression to allow only numeric and float values
        if (/^[0-9]*[.]?[0-9]*$/.test(value) && isValidNumber(value)) {
            setCurrencyInput(value);
        }
    };

    const handleKeyPressCurr = (event) => {

        // Allow the dot character (.) only if it doesn't already exist in the input value
        if (event.key === '.' && currencyInput.includes('.')) {
            event.preventDefault();
        }
    };

    const handleSelect = (item) => {
        setSelectedItem(item);
        setNetwork(item.network)
        setIsOpen(false);
        setTokenInput("")
        setCurrencyInput("")
    };

    const handleBuyTokenClick = async () => {

        if (selectedItem.curr === CONST.BNB || selectedItem.curr === CONST.ETH) {
            await wallet.directBuyTokens(currencyInput)
        }
        else {
            await wallet.directBuyTokensUSDT(currencyInput);
        }
        setCurrencyInput("")
    }

    const handleBuyMaxClick = async () => {
        if (selectedItem.curr === CONST.BNB || selectedItem.curr === CONST.ETH) {
            const maxAmount = await wallet.getMaxAmount()
            await wallet.directBuyTokens(maxAmount)
        }
        else {
            const maxUsdt = await wallet.getMaxUSDT();
            await wallet.directBuyTokensUSDT(maxUsdt);
        }
        setCurrencyInput("")
    }

    const handleLoadMaxClick = async () => {
        if (selectedItem.curr === CONST.BNB || selectedItem.curr === CONST.ETH) {
            const maxAmount = await wallet.getMaxAmount()
            setCurrencyInput(maxAmount)
        }
        else {
            const maxUsdt = await wallet.getMaxUSDT();
            setCurrencyInput(maxUsdt);
        }
    }

    return (

        <Row className='wrapper' style={{ minHeight: 850, paddingTop: 30, paddingBottom: 40 }} id="buyForm">
            <img src="/img/path@v-1.webp" alt="" style={{ position: 'absolute', left: 0, top: 100, 'z-index': 4 }}></img>
            <Col lg={6} md={12} className="d-flex justify-content-center buyform-info ">
                <div>
                    <div className="buyform-title">Presale Has Ended!</div>
                    <a href='https://playdoges.io'>PlayDoge PreSale</a>
                    <a href='https://pepeunchaineds.com'>Pepe Unchained PreSale</a>
                    <a href='https://wienedog.com'>WienerDog PreSale</a>
                    <p>🌈🚀 We are pleased to announce that Furrever Token Fluffy presale ended! 🐾 We will complete the last calculations then all sold tokens will be airdropped to our Furrever Family. We will burn all the rest of the tokens as soon as Launch completed. Thank you for your support! 🐱💖</p>
                </div>
            </Col>
            <Col lg={6} md={12} className="d-flex flex-column justify-content-center  ">
                <img src='/img/furrever-token-buy-hero-image.png' style={{ paddingLeft: 75, paddingRight: 75 }} />
                <div className='flex-grow-1 buyform-form'>
                    <div className="raised">USDT Raised <strong>$1,980,000.00</strong></div>
                    <div className="bar">
                        <div className="line" style={{ width: "100%" }}></div>
                        <div className="value">100%</div>
                    </div>
                    <ul className="numbers">
                        <li><span>Presale End Price: <strong>$0.000816</strong></span><span>Launch Price: <strong>$0.0009 (15X)</strong></span></li>
                    </ul>
                    <ul className="numbers-col">
                        <li><div>Total Balance</div><strong>1.2M $FURR</strong></li>
                    </ul>
                    <div className="connected-wallet">
                        <div><div>Connected Wallet</div></div>
                        <div><strong>{truncateMiddle(wallet?.currentAddress)}</strong></div>
                    </div>
                    <div className="amount-area">
                        <div className="token-selector">
                            <div className="dropdown" >
                                <button className="dropdown-toggle" onClick={() => setIsOpen(!isOpen)} style={{ border: 'none', backgroundColor: 'transparent', height: 50 }}>
                                    {selectedItem ? <img src={selectedItem.imageSrc} alt={selectedItem.text} style={{ width: '35px', height: '35px' }} /> : ''}
                                </button>
                                <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
                                    {currencies.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <div className="dropdown-item" onClick={() => handleSelect(item)} style={{ padding: 8 }}>
                                                <img src={item.imageSrc} alt={item.text} style={{ width: '30px', marginRight: '10px' }} />
                                                {item.text}
                                            </div>
                                            {index !== currencies.length - 1 && <div style={{ height: 2, backgroundColor: "#aaa", marginTop: 5, marginBottom: 5, marginLeft: 20, marginRight: 20 }}></div>}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="input-area">
                            <span className="max" onClick={handleLoadMaxClick}>MAX</span>
                            <input
                                value={currencyInput}
                                onChange={handleCurrencyInputChange}
                                onKeyPress={handleKeyPressCurr}
                                type="text" min="0" placeholder="0.00" />

                        </div>
                    </div>
                    <ul className="buttons">
                        <li><button onClick={handleBuyTokenClick}>BUY NOW</button></li>
                        <li><button onClick={handleBuyMaxClick} className="white">BUY MAX</button></li>
                    </ul>
                </div>


            </Col>
        </Row>
    );
};

export default BuyForm;
