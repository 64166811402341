import React from "react";
import "./WrapperLogo.css";

export const WrapperLogo = () => {
    return (
        <div class="wrapper">
            <div class="logos">
                <img src="/img/1-black.webp" alt="" />
                <img src="/img/2-black.webp" alt="" />
                <img src="/img/3-black.webp" alt="" />
                <img src="/img/4-black.webp" alt="" />
                <img src="/img/5-black.webp" alt="" />
            </div>
        </div>

    );
};
