import React from "react";
import "./WrapperInfo.css";

export const WrapperInfo = () => {
    const scrollToBuySection = () => {
        // Find the target section to scroll to
        const section = document.getElementById('buyForm');

        // Scroll to the section
        section.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div class="wrapper">
            <div class="info-cols">
                <div class="col">
                    <div class="row">
                        <div class="image-item"><img src="/img/furrever-token-info-image.webp" alt="" /></div>
                    </div>
                    <div class="row">
                        <a href="#buyForm" onClick={scrollToBuySection}>
                            <div><span>BUY NOW</span><img src="/img/path@info.webp" alt="" /></div>
                        </a>
                    </div>
                </div>
                <div class="col">
                    <div class="content">
                        <img src="/img/img@info.webp" alt="" />
                        <div class="label">Welcome to the purr-fect realm of digital finance! As your trusted guide, we whisker you into a whimsical world where finance meets feline fun.</div>
                        <p>Dive into a tail-wagging era of currency, where our innovative platform ensures your journey is as delightful as a kitten's playful leap. Join us and let's paw-sper together!</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
