import React from 'react';
import "./Footer.css";

export const Footer = () => {
   const scrollToBuySection = () => {
      // Find the target section to scroll to
      const section = document.getElementById('buyForm');

      // Scroll to the section
      section.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div id="wrapper" >
      <div class="page-footer">
   <div class="logo"><a href="/"><img src="img/logo-black.svg" alt="" /></a></div>
   <div class="content">
      <ul class="social">
         <li><a href="https://twitter.com/furrevers"><img src="img/icon@twitter.svg" alt="" /></a></li>
         <li><a href="https://www.instagram.com/furrevertoken/"><img src="img/icon@instagram.svg" alt="" /></a></li>
      </ul>
      <ul class="nav">
         <li><a href="#about">About</a></li>
         <li><a href="#tokenomics">Tokenomics</a></li>
         <li><a href="#faqs">FAQ's</a></li>
         <li><a href="#buyForm" onClick={scrollToBuySection} class="button">Buy Now</a></li>
         <li><a href="#how-to-buy">HOW TO BUY?</a></li>
      </ul>
      <p>Cryptocurrency markets can experience significant price fluctuations within short periods.
        Past performance is not indicative of future results, and there is no guarantee of profit or protection against losses.
      </p>
      <ul class="nav mini">
         <li><a href="#ookies-policy">Cookie Policy</a></li>
         <li><a href="#privacy-policy">Privacy Policy</a></li>
         <li><a href="#terms-conditions">Terms of Use</a></li>
      </ul>
   </div>
</div>
    </div>
  );
};
