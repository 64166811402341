import React from "react";
import "./Tokenomics.css";

export const Tokenomics = () => {
    const scrollToBuySection = () => {
        // Find the target section to scroll to
        const section = document.getElementById('buyForm');

        // Scroll to the section
        section.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div id="tokenomics">
            <div class="wrapper">
                <div class="tokenomics-wrapper">
                    <img src="/img/furrever-token-tokenomics-image.webp" alt="" class="image" />
                        <div class="label">Furrever Tokenomics</div>
                        <ul class="info">
                            <li>FURREVER</li>
                            <li>$FURR</li>
                        </ul>
                        <ul class="numbers">
                            <li>
                                <span>65%</span>
                                <div>5,850,000,000</div>
                                <strong>Presale</strong>
                            </li>
                            <li>
                                <span>25%</span>
                                <div>2,250,000,000</div>
                                <strong>DEX</strong>
                            </li>
                            <li>
                                <span>10%</span>
                                <div>900,000,000</div>
                                <strong>Team (Locked 1 Year)</strong>
                            </li>
                        </ul>
                        <ul class="buttons">
                            <li><a href="#buyForm" onClick={scrollToBuySection}>BUY NOW</a></li>
                            <li><a href="https://scrl.io/project/furrever/" target="_blank" class="white">AUDIT</a></li>
                        </ul>
                </div>
            </div>

        </div>
    );
};
