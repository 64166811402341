import React from "react";
import "./Faqs.css";

export const Faqs = () => {
    return (
        <div id="faqs">
            <div class="faq-container">
                <div class="wrapper wrapper-faq">
                    <img src="/img/path@faq01.webp" alt="" class="path01" />
                    <img src="/img/path@faq02.webp" alt="" class="path02" />
                    <img src="/img/path@faq03.webp" alt="" class="path03 show-mobile" />
                    <img src="/img/furrever-token-faq-right-image.webp" alt="" class="cat02" />
                    <div class="faq-area">
                        <div class="cols">
                            <div class="hero">
                                <div class="label">FAQs</div>
                                <div class="desc">Answers to Your Most Common Questions</div>
                                <img src="/img/furrever-token-faq-image.webp" alt="" class="cat01" />
                            </div>
                            <div class="items-area">
                                <div class="faq-items">
                                    <div class="faq-item">
                                        <div class="label">What is Furrever Token?</div>
                                    </div>
                                    <div class="faq-item">
                                        <div class="label">What makes Furrever Token unique?</div>
                                    </div>
                                    <div class="faq-item">
                                        <div class="label">How can I participate in the presale?</div>
                                    </div>
                                    <div class="faq-item">
                                        <div class="label">How many stages in presale?</div>
                                    </div>
                                    <div class="faq-item">
                                        <div class="label">Why Can't I see my tokens in my Trust Wallet?</div>
                                    </div>
                                    <div class="faq-item">
                                        <div class="label">How long does each presale stage last?</div>
                                    </div>
                                    <div class="faq-item">
                                        <div class="label">Are there any benefits to getting in early?</div>
                                    </div>
                                    <div class="faq-item">
                                        <div class="label">What is the total supply of Furrever Tokens?</div>
                                    </div>
                                    <div class="faq-item">
                                        <div class="label">How can I join the Furrever community?</div>
                                    </div>
                                    <div class="faq-item">
                                        <div class="label">Which blockchain is Furrever Token built on?</div>
                                    </div>
                                    <div class="faq-item">
                                        <div class="label">How can I check my FURR token balance?</div>
                                    </div>
                                    <div class="faq-item">
                                        <div class="label">Can you provide details about the smart contract?</div>
                                    </div>
                                    <div class="faq-item">
                                        <div class="label">Are there any audits conducted on the project?</div>
                                    </div>
                                    <div class="faq-item">
                                        <div class="label">Where can I trade Furrever Tokens?</div>
                                    </div>
                                    <div class="faq-item">
                                        <div class="label">How can I reach out to the Furrever support team?</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
