import React from "react";
import "./brands.css";

export const BrandsCarousel = () => {
    return (
        <div class="brands-carousel">
            
        </div>

    );
};
