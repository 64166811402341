import React from "react";
import "./Audit.css";

export const Audit = () => {
    const scrollToBuySection = () => {
        // Find the target section to scroll to
        const section = document.getElementById('buyForm');

        // Scroll to the section
        section.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div id="audit">
            <div class="wrapper">
                <div class="audit-area">
                    <div class="label">Purr-powered by the mightiest vibes in the feline realm.</div>
                    <p>Whether you're into DeFi, gaming, NFTs, or anything else, Furrever Token has something just for you. Our mission is to add excitement to the crypto world. Enjoy fun games, explore unique NFT collections, and discover DeFi opportunities on the Furrever Token platform.</p>
                    <ul class="buttons">
                        <li><a href="#buyForm" onClick={scrollToBuySection}>BUY NOW</a></li>
                        <li><a href="img/whitepaper.pdf" class="white">WHITEPAPER</a></li>
                    </ul>
                </div>
                <div class="audit-message">Infused with the strength of the mighty lion, yet as nimble as a cat's leap.</div>
                <div class="contract-info">
                    <strong>Contract Address</strong>
                    <div>
                        <a target="_blank" href="https://bscscan.com/token/0xA81B7DD0abD56bdc711Ad62859D4e05433ceed6b">0xA81B7DD0abD56bdc711Ad62859D4e05433ceed6b</a>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM5.00242 8L5.00019 20H14.9998V8H5.00242ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z"></path>
                        </svg>
                    </div>
                </div>
            </div>

        </div>
    );
};
