import React from "react";
import "./MediaContainer.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const MediaContainer = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        autoplaySpeed: 3000
    };
    return (
        <div class="media-container">
            <div class="hero">Furrever Media</div>
            <ul class="info">
                <li><a href="https://twitter.com/furrevers"><img src="img/icon@twitter.svg" alt="" /></a></li>
                <li><a href="https://www.instagram.com/furrevertoken/"><img src="img/icon@instagram.svg" alt="" /></a></li>
                <li>@FurreverToken</li>
            </ul>
            <div class="carousel-area">
                <Slider {...settings}>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-1.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-2.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-3.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-4.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-5.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-6.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-7.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-8.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-9.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-10.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-11.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-12.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-13.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-14.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-15.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="item-wrapper" tabindex="-1" >
                        <div class="item">
                            <div class="label"><img src="/img/avatar.webp" alt="" />
                                <div class="username">Furrever!<img src="img/badge.svg" alt="" /><span>@FurreverToken</span></div>
                            </div>
                            <div class="message-area"><img src="img/post-16.jpeg" alt="I JUST SOLD THE DOG ON TRUST WALLET!" /></div>
                            <div class="actions">
                                <ul>
                                    <li><span><img src="img/icon@like.svg" alt="" />Like</span></li>
                                    <li><span><img src="img/icon@share.svg" alt="" />Share</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
};
